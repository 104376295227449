import axios from "axios";

// All Levels
const allLevels = async (token: string, page: string | number) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const { data } = await axios.get(
        `https://zooma.lamiroir.online/public/api/v1/super-admin/levels/all?page=${
            page ? page : 1
        }`,
        config
    );
    return data;
};

// specific Level
const specificLevel = async (token: string, level_id: string) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const { data } = await axios.get(
        `https://zooma.lamiroir.online/public/api/v1/super-admin/levels/${level_id}`,
        config
    );
    return data;
};

// Change User's Level
const changeUserLevel = async (token: string, formData: object) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const data = await axios.post(
        `https://zooma.lamiroir.online/public/api/v1/super-admin/levels/change-user-level`,
        formData,
        config
    );
    return data;
};

// update Level
const updateLevel = async (
    token: string,
    level_id: string,
    formData: FormData
) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    };

    const { data } = await axios.post(
        `https://zooma.lamiroir.online/public/api/v1/super-admin/levels/update/${level_id}`,
        formData,
        config
    );
    return { data, formData };
};

const levelsServices = {
    allLevels,
    specificLevel,
    changeUserLevel,
    updateLevel,
};

export default levelsServices;
